import React, { useRef, useState, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";

function AudioPlayer({ audioFileUrl, c0, c1 }) {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const abortController = useRef(new AbortController());

  useEffect(() => {
    if (waveformRef.current) {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const linGradWithOpacity = ctx.createLinearGradient(0, 0, 1000, 128);
      linGradWithOpacity.addColorStop(0, lightenColor(c0, 0.7));
      linGradWithOpacity.addColorStop(1, lightenColor(c1, 0.7));

      const linGrad = ctx.createLinearGradient(0, 0, 1000, 128);
      linGrad.addColorStop(0, c0);
      linGrad.addColorStop(1, c1);

      wavesurfer.current = WaveSurfer.create({
        container: waveformRef.current,
        waveColor: linGradWithOpacity,
        progressColor: linGrad,
        cursorColor: c0,
        cursorWidth: 4,
        barWidth: 5,
        barRadius: 10,
        responsive: true,
        height: 80,
      });

      wavesurfer.current.on("finish", () => setIsPlaying(false));
      wavesurfer.current.on("ready", () => setIsLoading(false));
      wavesurfer.current.on("error", (e) => {
        console.error("WaveSurfer error:", e);
        setIsLoading(false);
      });
    }

    return () => {
      if (wavesurfer.current) {
        try {
          wavesurfer.current.destroy();
        } catch (error) {
          console.error("Error destroying WaveSurfer instance:", error);
        }
      }
      abortController.current.abort();
    };
  }, [c0, c1]);

  useEffect(() => {
    if (audioFileUrl && wavesurfer.current) {
      setIsLoading(true);

      const loadAudio = async () => {
        try {
          await wavesurfer.current.load(
            audioFileUrl,
            undefined,
            abortController.current.signal
          );
        } catch (e) {
          if (e.name === "AbortError") {
            console.log("Loading aborted");
          } else {
            console.error("Error loading audio:", e);
          }
        } finally {
          setIsLoading(false);
        }
      };

      loadAudio();
    }

    return () => {
      abortController.current.abort();
    };
  }, [audioFileUrl]);

  const handlePlayPause = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  // Helper function to lighten colors
  const lightenColor = (color, factor) => {
    const hex = color.replace(/^#/, "");
    const rgb = parseInt(hex, 16);
    const r = Math.min(
      255,
      Math.floor((rgb >> 16) + (255 - (rgb >> 16)) * factor)
    );
    const g = Math.min(
      255,
      Math.floor(((rgb >> 8) & 0x00ff) + (255 - ((rgb >> 8) & 0x00ff)) * factor)
    );
    const b = Math.min(
      255,
      Math.floor((rgb & 0x0000ff) + (255 - (rgb & 0x0000ff)) * factor)
    );
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
  };

  return (
    <div className="w-full mx-auto mt-8 p-4 bg-white rounded-lg">
      <div ref={waveformRef} className="mb-4" />
      {audioFileUrl && (
        <button
          onClick={handlePlayPause}
          disabled={isLoading}
          className={`${
            isLoading
              ? "text-gray-400 cursor-not-allowed"
              : "text-gray-900 hover:text-gray-600"
          } p-2 rounded-full transition duration-300`}>
          {isLoading ? (
            <svg
              className="animate-spin h-8 w-8"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24">
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
            </svg>
          ) : isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fillRule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                clipRule="evenodd"
              />
            </svg>
          ) : (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-8 w-8"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                clipRule="evenodd"
              />
            </svg>
          )}
        </button>
      )}
    </div>
  );
}

export default AudioPlayer;
