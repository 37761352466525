import React, { useState, useEffect } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import react typed
import { ReactTyped } from "react-typed";

const SpotifyRoastPage = () => {
  const [accessToken, setAccessToken] = useState(null);
  const [userTopTracks, setUserTopTracks] = useState([]);
  const [userTopArtists, setUserTopArtists] = useState([]);
  const [roast, setRoast] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    // Extract token from URL hash
    const hash = window.location.hash
      .substring(1)
      .split("&")
      .reduce((initial, item) => {
        if (item) {
          const parts = item.split("=");
          initial[parts[0]] = decodeURIComponent(parts[1]);
        }
        return initial;
      }, {});

    if (hash.access_token) {
      // Store the access token
      setAccessToken(hash.access_token);
      localStorage.setItem("accessToken", hash.access_token); // Save to localStorage
      window.location.hash = "";
    } else {
      const storedToken = localStorage.getItem("accessToken");
      if (storedToken) {
        setAccessToken(storedToken);
      }
    }
  }, []);

  useEffect(() => {
    if (accessToken) {
      fetchUserTopItems(accessToken);
    }
  }, [accessToken]);

  const handleLogin = () => {
    const clientId = "a79fea09511947dc8c1d0c2f1bb722f8";
    const redirectUri = encodeURIComponent("https://hummify.girgin.co/roast");
    const scopes =
      "playlist-read-private playlist-read-collaborative user-library-read user-top-read";
    window.location = `https://accounts.spotify.com/authorize?client_id=${clientId}&response_type=token&redirect_uri=${redirectUri}&scope=${scopes}`;
  };

  const fetchUserTopItems = async (token) => {
    try {
      const [tracksResponse, artistsResponse] = await Promise.all([
        axios.get("https://api.spotify.com/v1/me/top/tracks", {
          headers: { Authorization: `Bearer ${token}` },
        }),
        axios.get("https://api.spotify.com/v1/me/top/artists", {
          headers: { Authorization: `Bearer ${token}` },
        }),
      ]);
      setUserTopTracks(tracksResponse.data.items);
      setUserTopArtists(artistsResponse.data.items);
      generateRoast(tracksResponse.data.items, artistsResponse.data.items);
    } catch (error) {
      console.error("Error fetching user data:", error);
      if (error.response && error.response.status === 401) {
        setError("Access token expired. Please log in again.");
        setAccessToken(null);
        localStorage.removeItem("accessToken");
      } else {
        setError("Failed to fetch user data. Please try logging in again.");
      }
    }
  };

  const generateRoast = async (tracks, artists) => {
    try {
      const response = await axios.post(
        "https://api.openai.com/v1/chat/completions",
        {
          model: "gpt-4o",
          messages: [
            {
              role: "system",
              content:
                "Sen çocuklar duymasın Selamisin. Senden şakacı ve aşağılayıcı bir zorba olmanı istiyorum. . Sana müzik listesi vereceğim ve bu listeyle dalga geç. Ayrıca türkçe ifadeleri düzgün kullanmaya özen göster. Also insert the ^1000 or ^2000 or ^200 or number can be range from 100 to 5000 after every sentence to make the roast slower or dramatic and use phrases like kardeşim, abicim or humiliating phrases. write '\n' at the end of every sentence. Türkçe yazmanı istiyorum.",
            },
            {
              role: "user",
              content: `Roast my music taste. My top tracks: ${tracks
                .map((t) => t.name)
                .join(", ")}. My top artists: ${artists
                .map((a) => a.name)
                .join(", ")}.`,
            },
          ],
        },

        {
          headers: {
            Authorization: `Bearer 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`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data.choices[0].message.content);

      setRoast(response.data.choices[0].message.content.replace(/\n/g, "<br>"));
    } catch (error) {
      console.error("Error generating roast:", error);
      setRoast(
        "Failed to generate a roast. Your music taste is beyond criticism."
      );
    }
  };

  return (
    <div className="w-full flex flex-col items-start justify-start px-4 md:px-8 lg:px-16 pt-16 p-16 relative">
      <motion.h1
        className="text-4xl font-bold text-center mb-6 text-white p relative z-10"
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}>
        Spotify Roast
      </motion.h1>

      {error && (
        <motion.p
          className="text-red-500 text-center mb-4"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}>
          {error}
        </motion.p>
      )}

      {!accessToken ? (
        <motion.button
          onClick={handleLogin}
          className="bg-white text-[#1DB954] font-bold py-2 px-4 rounded-full text-lg mx-auto block transition duration-300 ease-in-out transform hover:scale-105 relative z-10"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}>
          Login with Spotify
        </motion.button>
      ) : (
        <motion.div className="text-black text-4xl font-appleGaramond font-thin text-left">
          <ReactTyped
            strings={[roast]}
            typeSpeed={10}
            loop={false}
            showCursor={true}
          />
        </motion.div>
      )}
    </div>
  );
};

export default SpotifyRoastPage;
