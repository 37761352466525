import React from "react";
import PostCard from "./PostCard";
import AudioPlayer from "./AudioPlayer";
import SpotifyRoastPage from "./SpotifyRoastPage";

function SignInPage({ onSignIn, latestPosts, user, continueWithUser }) {
  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-start bg-white px-4 md:px-8 lg:px-16">
      <header className="sticky top-0 z-20 bg-white w-full flex justify-between items-center py-4  shadow-sm">
        <h1 className="text-3xl font-extrabold text-gray-900 font-appleGaramond tracking-wide">
          Hummify
        </h1>
        {user && (
          <button
            onClick={continueWithUser}
            className="text-gray-600 font-light font-appleGaramond flex items-center">
            <span className="hidden md:inline">Continue as </span>
            <span className="font-medium ml-1">{user.displayName}</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4 ml-1"
              viewBox="0 0 20 20"
              fill="currentColor">
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        )}
      </header>

      {/* Header Section */}
      <div className="md:max-w-6xl flex flex-col items-start mb-12 mt-12">
        <h1 className="text-4xl md:text-6xl font-extrabold text-left mb-6">
          <span className="text-gray-900 font-appleGaramond ">Find that </span>

          <span className="relative inline-block ">
            <span
              className=" absolute transform -skew-x-12 inset-0  bg-gradient-to-tl from-purple-500 via-pink-500 to-red-500 rounded-2xl "
              style={{}}></span>

            <span
              className="transform -skew-x-12 rounded-2xl "
              style={{
                backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
                backgroundRepeat: "repeat",
                backgroundSize: "100px 100px",
                opacity: 0.5,
                mixBlendMode: "luminosity",
              }}></span>
            <span className="relative z-10 text-white m-4 font-appleGaramond">
              song
            </span>
          </span>

          <span className="text-gray-900 font-appleGaramond">
            {" "}
            stuck on the tip of your tongue!
          </span>
        </h1>
        <p className="text-base md:text-xl font-light text-left leading-relaxed mb-6 text-gray-600">
          Have a tune stuck in your head but can't recall the name? Let our
          community of music lovers help you out! Share the melody, lyrics, or
          anything you remember, and get suggestions from other users. Together,
          let's find that song!
        </p>
      </div>

      {/* How It Works Section */}
      <section className="w-full max-w-3xl mt-12 mx-auto p-6 bg-gradient-to-br from-gray-400 via-gray-600 to-gray-900 rounded-lg transition-all duration-300 relative overflow-hidden">
        <div
          className="absolute inset-0 opacity-10"
          style={{
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,

            backgroundRepeat: "repeat",
          }}></div>
        <div className="absolute inset-0 bg-gradient-to-tl from-purple-500/30 via-pink-500/30 to-red-500/30 mix-blend-overlay"></div>
        <h2 className="text-3xl font-extrabold mb-6 text-center text-white relative z-10 font-appleGaramond">
          How It Works
        </h2>
        <p className="text-lg text-white mb-4 relative z-10">
          <strong>1. Share</strong> a melody, lyrics, or anything you remember
          about the song.
        </p>
        <p className="text-lg  text-white mb-4 relative z-10">
          <strong>2. Get</strong> suggestions from other users.
        </p>
        <p className="text-lg  text-white mb-4 relative z-10">
          <strong>3. Identify</strong> the song with community help.
        </p>
      </section>

      <section className="w-full max-w-7xl mt-16 mx-auto ">
        <div className="flex flex-col bg-slate-200 p-4 rounded-lg">
          <h2 className="text-3xl font-semibold mb-8 text-gray-800 font-appleGaramond">
            Latest Posts
          </h2>
          {latestPosts.length > 0 ? (
            <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {latestPosts.slice(0, 6).map((post) => (
                <PostCard key={post.id} post={post} />
              ))}
            </ul>
          ) : (
            <div className="text-center py-12 px-4 sm:px-6 lg:px-8">
              <h3 className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
                Be the first to post!
              </h3>
              <p className="mt-4 text-lg text-gray-500">
                No posts yet. Start the melody and be the first to share your
                musical mystery!
              </p>
              <div className="mt-6">
                <button
                  onClick={onSignIn}
                  className="inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700">
                  Create the First Post
                </button>
              </div>
            </div>
          )}
        </div>

        <section className="w-full max-w-3xl mt-12 mx-auto p-6 bg-gradient-to-br from-gray-400 via-gray-600 to-gray-900 rounded-lg transition-all duration-300 relative overflow-hidden">
          <div
            className="absolute inset-0 opacity-10"
            style={{
              backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
              backgroundRepeat: "repeat",
            }}></div>
          <div className="absolute inset-0 bg-gradient-to-tl from-purple-500/30 via-pink-500/30 to-red-500/30 mix-blend-overlay"></div>
          <h2 className="text-3xl font-extrabold mb-6 text-center text-white relative z-10 font-appleGaramond">
            Consider Yourself a Music Expert?
          </h2>
          <p className="text-lg text-white mb-4 relative z-10">
            Join us and help others! By sharing your musical knowledge, you can
            become a valuable member of our community.
          </p>
          <p className="text-lg text-white mb-4 relative z-10">
            At Hummify, music enthusiasts come together to assist one another.
            Your expertise could be the key to helping someone find the track
            they've been searching for!
          </p>
        </section>

        <div className="flex justify-center mt-10 max-h-fit">
          {user ? (
            <button
              onClick={continueWithUser}
              className="bg-[#000000] hover:bg-[#000000] text-white py-3 px-6 rounded-full shadow-lg text-lg font-medium transition duration-300 mt-4 mb-32 font-appleGaramond">
              Continue with Another Account
            </button>
          ) : (
            <button
              onClick={onSignIn}
              className="bg-gradient-to-r from-red-500 to-red-600 hover:from-red-600 hover:to-red-700 text-white py-2 px-6 rounded-full shadow-md text-2xl font-semibold transition-all duration-300 mb-4">
              Join for Free
            </button>
          )}
        </div>
      </section>
    </div>
  );
}

export default SignInPage;
