import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import {
  get,
  add,
  listenForComments,
  addNotification,
  getUsersWhoCommented,
} from "./firebaseFirestore";
import PostCard from "./PostCard";
import { FaArrowLeft, FaSpinner } from "react-icons/fa";
import { auth } from "./firebaseConfig";

const PostDetailsPage = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setUser(user);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const postData = await get("posts", postId);
        if (postData) {
          setPost(postData);
        } else {
          throw new Error("Post not found");
        }
        setLoading(false);
      } catch (err) {
        console.error("Failed to fetch post:", err);
        setError(err.message || "Failed to load the post.");
        setLoading(false);
      }
    };

    fetchPost();

    // Set up real-time listener for comments
    const unsubscribe = listenForComments(postId, async (commentsArray) => {
      const commentsWithPhotos = await Promise.all(
        commentsArray.map(async (comment) => {
          const userProfile = await get("users", comment.userId);
          return {
            ...comment,
            photoURL: userProfile?.photoURL || "https://via.placeholder.com/40",
          };
        })
      );
      setComments(commentsWithPhotos);
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [postId]);

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (newComment.trim() === "" || !user) return;

    try {
      await add(`posts/${postId}/comments`, {
        content: newComment,
        author: user.displayName,
        userId: user.uid,
        createdAt: new Date(),
      });

      // Get users who have commented on this post
      const usersWhoCommented = await getUsersWhoCommented(postId);

      // Add notification for the post owner
      if (post.userId !== user.uid) {
        await addNotification(post.userId, {
          message: `${
            user.displayName
          } commented on your post: "${newComment.substring(0, 50)}${
            newComment.length > 50 ? "..." : ""
          }"`,
          postId: postId,
          createdAt: new Date(),
        });
      }

      // Add notification for users who have commented before (excluding the current user and post owner)
      for (const commenterId of usersWhoCommented) {
        if (commenterId !== user.uid && commenterId !== post.userId) {
          await addNotification(commenterId, {
            message: `${
              user.displayName
            } also commented on a post you've commented on: "${newComment.substring(
              0,
              50
            )}${newComment.length > 50 ? "..." : ""}"`,
            postId: postId,
            createdAt: new Date(),
          });
        }
      }

      setNewComment("");
    } catch (error) {
      console.error("Error adding comment:", error);
    }
  };

  if (loading)
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-100">
        <FaSpinner className="animate-spin text-4xl text-gray-600" />
      </div>
    );

  if (error)
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-100">
        <h2 className="text-2xl font-bold text-red-600 mb-4">Error</h2>
        <p className="text-gray-700">{error}</p>
        <Link to="/home" className="mt-6 text-blue-600 hover:underline">
          Go back to home
        </Link>
      </div>
    );

  if (!post)
    return (
      <div className="w-full h-screen flex flex-col items-center justify-center bg-gray-100">
        <h2 className="text-2xl font-bold text-gray-800 mb-4">
          Post not found
        </h2>
        <Link to="/" className="mt-2 text-blue-600 hover:underline">
          Go back to home
        </Link>
      </div>
    );

  return (
    <div className="w-full min-h-screen bg-white">
      <div className="max-w-4xl mx-auto px-4 py-8">
        <Link
          to="/home"
          className="inline-flex items-center text-gray-600 hover:text-gray-800 mb-6 transition-colors duration-200 font-appleGaramond">
          <FaArrowLeft className="mr-2" size={12} />
          Back to Home
        </Link>
        <div className="bg-white rounded-lg  overflow-hidden">
          <div className="">
            <PostCard post={post} />
          </div>
          <div className="bg-gray-50 px-6 py-4 m-3">
            <h3 className="text-lg font-semibold text-gray-800 mb-2">
              Guesses
            </h3>
            <div className="space-y-4">
              {comments.length > 0 ? (
                comments.map((comment) => (
                  <div
                    key={comment.id}
                    className="bg-white p-3 rounded-md shadow-sm flex items-start">
                    <img
                      src={comment.photoURL}
                      alt={`${comment.author}'s profile`}
                      className="w-10 h-10 rounded-full mr-3"
                    />
                    <div>
                      <p className="text-sm text-gray-800">{comment.content}</p>
                      <p className="text-xs text-gray-500 mt-1">
                        {comment.author} -{" "}
                        {new Date(comment.createdAt.toDate()).toLocaleString()}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-600">No comments yet.</p>
              )}
            </div>
            {user && (
              <form onSubmit={handleAddComment} className="mt-4">
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  className="w-full p-2 border border-gray-300 rounded-md focus:ring-gray-500 focus:border-gray-500 text-sm"
                  placeholder="Do you know what this is?"
                  rows="3"
                  // expandable no
                  style={{ resize: "none" }}
                />
                <button
                  type="submit"
                  className="mt-2 px-6 py-2 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white text-sm font-semibold rounded-full shadow-md transition-all duration-300">
                  Make your guess
                </button>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostDetailsPage;
