import React, { useState, useEffect, useRef } from "react";
import { useNavigate, Link } from "react-router-dom";
import PostCard from "./PostCard";
import {
  add,
  get,
  listenForPosts,
  getNotifications,
} from "./firebaseFirestore";
import { uploadFile } from "./firebaseStorage";
import AudioPlayer from "./AudioPlayer";

function HomePage({ user, onSignOut }) {
  const navigate = useNavigate();
  const [posts, setPosts] = useState([]);
  const [newPostContent, setNewPostContent] = useState("");
  const [audioFile, setAudioFile] = useState(null);
  const [audioUrl, setAudioUrl] = useState("");
  const [isSharing, setIsSharing] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState(user?.photoURL || null);
  const [comments, setComments] = useState({});
  const [showCommentInput, setShowCommentInput] = useState({});
  const [currentComment, setCurrentComment] = useState("");
  const [showWarning, setShowWarning] = useState(false);
  const [warningMessage, setWarningMessage] = useState("");
  const [notificationCount, setNotificationCount] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const mediaRecorderRef = useRef(null);
  const [liveAudioUrl, setLiveAudioUrl] = useState(null);
  const streamRef = useRef(null);

  useEffect(() => {
    if (!user) {
      navigate("/");
      return;
    }

    // Set up real-time listener for posts
    const unsubscribe = listenForPosts(async (postsArray) => {
      try {
        const postsWithUserData = await Promise.all(
          postsArray.map(async (post) => {
            const userData = await get("users", post.userId);
            return {
              ...post,
              author: userData ? userData.displayName : "Unknown",
              authorPhotoURL: userData ? userData.photoURL : null,
            };
          })
        );

        setPosts(postsWithUserData);

        // Fetch comments for each post
        postsWithUserData.forEach(async (post) => {
          try {
            const postComments = await get(`posts/${post.id}/comments`);
            setComments((prevComments) => ({
              ...prevComments,
              [post.id]: postComments,
            }));
          } catch (error) {
            console.error(
              `Error fetching comments for post ${post.id}:`,
              error
            );
          }
        });
      } catch (error) {
        console.error("Error processing posts:", error);
      }
    });

    // Fetch notifications count
    const fetchNotificationCount = async () => {
      try {
        const notifications = await getNotifications(user.uid);
        setNotificationCount(notifications.length);
      } catch (error) {
        console.error("Error fetching notifications:", error);
      }
    };

    fetchNotificationCount();

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, [user, navigate]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAudioFile(file);
      const url = URL.createObjectURL(file);
      setAudioUrl(url);
      setShowWarning(false);
    }
  };

  const handleRemoveAudio = () => {
    setAudioFile(null);
    setAudioUrl("");
    setRecordedAudio(null);
    setLiveAudioUrl(null);
  };

  const handleAddPost = async (e) => {
    e.preventDefault();
    if (newPostContent.trim() === "" && !audioFile && !recordedAudio) {
      setWarningMessage("Please enter some text or add an audio file.");
      setShowWarning(true);
      return;
    }

    if (!audioFile && !recordedAudio) {
      setWarningMessage(
        "Adding an audio file helps other users make better guesses. Are you sure you want to post without audio?"
      );
      setShowWarning(true);
      return;
    }

    await submitPost();
  };

  const submitPost = async (ignoreWarning = false) => {
    if (!ignoreWarning && !audioFile && !recordedAudio) {
      setWarningMessage(
        "Adding an audio file helps other users make better guesses. Are you sure you want to post without audio?"
      );
      setShowWarning(true);
      return;
    }

    setIsSharing(true);

    try {
      let uploadedAudioUrl = null;
      if (audioFile) {
        const path = `audio/${Date.now()}_${audioFile.name}`;
        uploadedAudioUrl = await uploadFile(audioFile, path);
      } else if (recordedAudio) {
        const path = `audio/${Date.now()}_recorded_audio.ogg`;
        uploadedAudioUrl = await uploadFile(
          new File(
            [await fetch(recordedAudio).then((r) => r.blob())],
            "recorded_audio.ogg",
            { type: "audio/ogg" }
          ),
          path
        );
      }

      await add("posts", {
        content: newPostContent,
        author: user.displayName,
        userId: user.uid,
        createdAt: new Date(),
        audioUrl: uploadedAudioUrl,
        authorPhotoURL: user.photoURL,
      });

      setNewPostContent("");
      setAudioFile(null);
      setAudioUrl("");
      setRecordedAudio(null);
      setShowWarning(false);
      setLiveAudioUrl(null);
    } catch (error) {
      console.error("Error adding post:", error);
    } finally {
      setIsSharing(false);
    }
  };

  const toggleCommentInput = (postId) => {
    setShowCommentInput((prev) => ({
      ...prev,
      [postId]: !prev[postId],
    }));
  };

  const handlePostClick = (postId) => {
    console.log("Post clicked:", postId);
    navigate(`/posts/${postId}`);
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      streamRef.current = stream;
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/ogg; codecs=opus" });
        const audioUrl = URL.createObjectURL(blob);
        setRecordedAudio(audioUrl);
        setAudioFile(
          new File([blob], "recorded_audio.ogg", { type: "audio/ogg" })
        );
        setLiveAudioUrl(null);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
      setLiveAudioUrl(URL.createObjectURL(stream));
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
        streamRef.current = null;
      }
    }
  };

  if (!user) {
    return null;
  }

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-white px-4 md:px-8 lg:px-16">
      {/* Header */}
      <header className="sticky top-0 z-20 bg-white w-full flex justify-between items-center py-4  shadow-sm">
        <h1 className="text-3xl font-extrabold text-gray-900 font-appleGaramond">
          Hummify
        </h1>
        <div className="flex items-center space-x-4">
          <button
            onClick={() => navigate("/notifications")}
            className="text-gray-600 hover:text-gray-800 transition-colors relative">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
              />
            </svg>
            {notificationCount > 0 && (
              <span className="absolute -top-1 -right-1 bg-red-500 text-white text-xs rounded-full h-4 w-4 flex items-center justify-center">
                {notificationCount}
              </span>
            )}
          </button>
          <button
            onClick={onSignOut}
            className="text-sm text-gray-600 transition-colors">
            Sign Out
          </button>
        </div>
      </header>

      {/* Post Creation Form */}
      <div className="w-full max-w-3xl bg-white rounded-lg p-4 mb-8 shadow-sm mt-4">
        <form onSubmit={handleAddPost} className="flex flex-col space-y-2">
          <textarea
            value={newPostContent}
            onChange={(e) => setNewPostContent(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded-md focus:ring-gray-500 focus:border-gray-500 text-sm resize-none"
            placeholder="Describe the song or lyrics you remember..."
            rows="3"
          />

          <div className="flex flex-row justify-start">
            <label className="text-sm flex items-center cursor-pointer bg-gradient-to-r from-gray-200 to-gray-300 hover:from-gray-300 hover:to-gray-400 text-gray-700 px-3 py-1 rounded-full transition-colors">
              <span>Add Audio</span>
              <input
                type="file"
                accept="audio/*"
                onChange={handleFileChange}
                className="hidden"
              />
            </label>

            <button
              type="button"
              onClick={isRecording ? stopRecording : startRecording}
              className={`text-sm cursor-pointer mx-3 px-3 py-1 rounded-full transition-colors ${
                isRecording
                  ? "bg-red-500 text-white"
                  : "bg-gradient-to-r from-blue-400 to-blue-500 hover:from-blue-500 hover:to-blue-600 text-white"
              }`}>
              {isRecording ? "Stop Recording" : "Record Audio"}
            </button>

            <div className="flex-grow"></div>

            <button
              type="submit"
              disabled={isSharing}
              className="px-6 py-2 bg-gradient-to-r from-green-500 to-green-600 hover:from-green-600 hover:to-green-700 text-white text-sm font-semibold rounded-full shadow-md transition-all disabled:opacity-50 disabled:cursor-not-allowed">
              {isSharing ? "Posting..." : "Post"}
            </button>
          </div>

          {(audioFile || recordedAudio || liveAudioUrl) && (
            <div className="flex items-center space-x-2 mt-2">
              <span className="text-sm text-gray-600">
                {audioFile
                  ? audioFile.name
                  : recordedAudio
                  ? "Recorded Audio"
                  : "Live Recording"}
              </span>
              <button
                onClick={handleRemoveAudio}
                className="text-red-500 hover:text-red-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  class="w-6 h-6">
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          )}
        </form>
        {(audioFile || recordedAudio) && !isRecording && (
          <div className="mt-4">
            <AudioPlayer
              audioFileUrl={recordedAudio || URL.createObjectURL(audioFile)}
              c0="#4B5563"
              c1="#9CA3AF"
            />
          </div>
        )}
      </div>

      {/* Warning Popup */}
      {showWarning && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={() => setShowWarning(false)}>
          <div
            className="bg-white rounded-lg p-6 max-w-sm"
            onClick={(e) => e.stopPropagation()}>
            <p className="text-gray-800 mb-4">{warningMessage}</p>
            {warningMessage !==
              "Please enter some text or add an audio file." && (
              <div className="flex justify-end space-x-2">
                <button
                  onClick={() => setShowWarning(false)}
                  className="px-4 py-2 bg-gradient-to-r from-gray-400 to-gray-500 hover:from-gray-500 hover:to-gray-600 text-white rounded-full transition-colors relative overflow-hidden">
                  <span className="relative z-10">Cancel</span>
                  <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMDAiIGhlaWdodD0iMzAwIj48ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIwIj48ZmVUdXJidWxlbmNlIHR5cGU9ImZyYWN0YWxOb2lzZSIgYmFzZUZyZXF1ZW5jeT0iLjc1IiBzdGl0Y2hUaWxlcz0ic3RpdGNoIi8+PGZlQ29sb3JNYXRyaXggdHlwZT0ic2F0dXJhdGUiIHZhbHVlcz0iMCIvPjwvZmlsdGVyPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbHRlcj0idXJsKCNhKSIgb3BhY2l0eT0iLjA1Ii8+PC9zdmc+')] opacity-25"></div>
                </button>
                <button
                  onClick={() => submitPost(true)}
                  className="px-4 py-2 bg-gradient-to-r from-yellow-400 to-yellow-500 hover:from-yellow-500 hover:to-yellow-600 text-white rounded-full  transition-colors relative overflow-hidden">
                  <span className="relative z-10">Post anyway</span>
                  <div className="absolute inset-0 bg-[url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMDAiIGhlaWdodD0iMzAwIj48ZmlsdGVyIGlkPSJhIiB4PSIwIiB5PSIwIj48ZmVUdXJidWxlbmNlIHR5cGU9ImZyYWN0YWxOb2lzZSIgYmFzZUZyZXF1ZW5jeT0iLjc1IiBzdGl0Y2hUaWxlcz0ic3RpdGNoIi8+PGZlQ29sb3JNYXRyaXggdHlwZT0ic2F0dXJhdGUiIHZhbHVlcz0iMCIvPjwvZmlsdGVyPjxyZWN0IHdpZHRoPSIxMDAlIiBoZWlnaHQ9IjEwMCUiIGZpbHRlcj0idXJsKCNhKSIgb3BhY2l0eT0iLjA1Ii8+PC9zdmc+')] opacity-25"></div>
                </button>
              </div>
            )}
          </div>
        </div>
      )}

      {/* Posts Display */}
      <div className="w-full max-w-3xl space-y-6 mb-32 mt-24">
        {posts.length > 0 ? (
          posts.map((post) => (
            <div onClick={() => handlePostClick(post.id)} key={post.id}>
              <PostCard
                post={post}
                comments={comments[post.id] || []}
                onToggleCommentInput={toggleCommentInput}
                showCommentInput={showCommentInput[post.id]}
                comment={currentComment}
                setComment={setCurrentComment}
                currentUser={user}
              />
            </div>
          ))
        ) : (
          <div className="text-center py-8 text-gray-500">
            No posts yet. Be the first to share!
          </div>
        )}
      </div>
    </div>
  );
}

export default HomePage;
