// src/firebaseFirestore.js
import {
  collection,
  addDoc,
  getDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { db } from "./firebaseConfig";

// Add a new document
export const add = async (collectionName, data) => {
  try {
    const docRef = await addDoc(collection(db, collectionName), data);
    return docRef.id;
  } catch (error) {
    console.error("Error adding document:", error);
    throw error;
  }
};

// Listen for real-time updates on comments
export const listenForComments = (postId, callback) => {
  try {
    const commentsQuery = query(
      collection(db, `posts/${postId}/comments`),
      orderBy("createdAt", "desc"),
      limit(20)
    );

    return onSnapshot(commentsQuery, (querySnapshot) => {
      const comments = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(comments);
    });
  } catch (error) {
    console.error("Error listening for comments:", error);
    throw error;
  }
};

// Get a single document
export const get = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? { id: docSnap.id, ...docSnap.data() } : null;
  } catch (error) {
    console.error("Error getting document:", error);
    throw error;
  }
};

// Update an existing document
export const update = async (collectionName, documentId, data) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await updateDoc(docRef, data);
  } catch (error) {
    console.error("Error updating document:", error);
    throw error;
  }
};

// Delete a document
export const remove = async (collectionName, documentId) => {
  try {
    const docRef = doc(db, collectionName, documentId);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error deleting document:", error);
    throw error;
  }
};

// Get latest posts
export const getLatestPosts = async () => {
  try {
    const postsQuery = query(
      collection(db, "posts"),
      orderBy("createdAt", "desc"),
      limit(10)
    );
    const querySnapshot = await getDocs(postsQuery);
    return querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  } catch (error) {
    console.error("Error getting latest posts:", error);
    throw error;
  }
};

// Listen for real-time updates to posts
export const listenForPosts = (callback) => {
  const postsQuery = query(
    collection(db, "posts"),
    orderBy("createdAt", "desc"),
    limit(10)
  );

  const unsubscribe = onSnapshot(postsQuery, async (snapshot) => {
    try {
      const postsArray = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      callback(postsArray);
    } catch (error) {
      console.error("Error listening for posts:", error);
    }
  });

  return unsubscribe; // Return the unsubscribe function to stop listening when needed
};

// Add notification
export const addNotification = async (userId, notification) => {
  await add(`users/${userId}/notifications`, notification);
};

// Get notifications
export const getNotifications = async (userId) => {
  try {
    console.log("Getting notifications for user:", userId);
    const notificationsRef = collection(db, `users/${userId}/notifications`);
    const notificationsSnapshot = await getDocs(notificationsRef);
    const notifications = notificationsSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log("Retrieved notifications:", notifications);
    return notifications;
  } catch (error) {
    console.error("Error in getNotifications:", error);
    throw error;
  }
};
// Mark notification as read
export const markNotificationAsRead = async (userId, notificationId) => {
  try {
    const notificationRef = doc(
      db,
      `users/${userId}/notifications`,
      notificationId
    );
    await deleteDoc(notificationRef);
  } catch (error) {
    console.error("Error marking notification as read:", error);
    throw error;
  }
};

// Get users who have commented on a post
export const getUsersWhoCommented = async (postId) => {
  try {
    const commentsRef = collection(db, `posts/${postId}/comments`);
    const commentsSnapshot = await getDocs(commentsRef);
    const userIds = new Set();
    commentsSnapshot.docs.forEach((doc) => {
      const comment = doc.data();
      if (comment.userId) {
        userIds.add(comment.userId);
      }
    });
    return Array.from(userIds);
  } catch (error) {
    console.error("Error getting users who commented:", error);
    throw error;
  }
};
