import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { getNotifications, markNotificationAsRead } from "./firebaseFirestore";
import { useUser } from "./UserContext";
import { FaArrowLeft } from "react-icons/fa";

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      const fetchNotifications = async () => {
        const userNotifications = await getNotifications(user.uid);
        setNotifications(userNotifications);
      };
      fetchNotifications();
    }
  }, [user]);

  const handleMarkAsRead = async (notificationId) => {
    if (user) {
      await markNotificationAsRead(user.uid, notificationId);
      setNotifications(notifications.filter((n) => n.id !== notificationId));
    }
  };

  if (!user) {
    return (
      <div className="w-full min-h-screen flex items-center justify-center bg-white">
        <p className="text-xl text-gray-600">
          Please sign in to view notifications.
        </p>
      </div>
    );
  }

  return (
    <div className="w-full min-h-screen flex flex-col items-center justify-start bg-white px-4 md:px-8 lg:px-16 pt-16">
      <div className="w-full max-w-3xl">
        <Link
          to="/home"
          className="inline-flex items-center text-gray-600 hover:text-gray-800 mb-6 transition-colors duration-200 font-appleGaramond">
          <FaArrowLeft className="mr-2" size={12} />
          Back to Home
        </Link>
        <h1 className="text-3xl font-extrabold text-gray-900 font-appleGaramond mb-8">
          Notifications
        </h1>
        {notifications.length === 0 ? (
          <p className="text-lg text-gray-600">No new notifications.</p>
        ) : (
          <ul className="space-y-4">
            {notifications.map((notification) => (
              <li
                key={notification.id}
                className="bg-white rounded-lg shadow-md p-4 border border-gray-200">
                <p className="mb-2 text-gray-800">{notification.message}</p>
                <div className="flex justify-between items-center">
                  <Link
                    to={`/posts/${notification.postId}`}
                    className="text-blue-500 hover:text-blue-600 transition-colors duration-200">
                    View Post
                  </Link>
                  <button
                    onClick={() => handleMarkAsRead(notification.id)}
                    className="px-3 py-1 bg-gray-200 text-gray-700 rounded-full text-sm hover:bg-gray-300 transition-colors duration-200">
                    Mark as Read
                  </button>
                </div>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
}

export default Notifications;
