// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB-LNYIUVwNeUZBll4SZSWZ4Mno0MlLqSA",
  authDomain: "hummify-70026.firebaseapp.com",
  projectId: "hummify-70026",
  storageBucket: "hummify-70026.appspot.com",
  messagingSenderId: "300897095889",
  appId: "1:300897095889:web:5609699e581152d84f35f0",
  measurementId: "G-K8L84NJYSL",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const db = getFirestore(app);
const storage = getStorage(app);

export { app, analytics, auth, provider, db, storage };
