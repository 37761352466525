// src/PostCard.jsx
import React, { useMemo } from "react";
import AudioPlayer from "./AudioPlayer";

function PostCard({ post }) {
  // Function to generate a random color in hex format
  const getRandomColor = () => {
    return `#${Math.floor(Math.random() * 16777215)
      .toString(16)
      .padStart(6, "0")}`;
  };

  // Generate random colors and gradient
  const randomColor1 = useMemo(() => getRandomColor(), []);
  const randomColor2 = useMemo(() => getRandomColor(), []);

  const gradient = useMemo(() => {
    const angle = Math.floor(Math.random() * 360);
    return `linear-gradient(${angle}deg, ${randomColor1}, ${randomColor2})`;
  }, [randomColor1, randomColor2]);

  return (
    <li className="bg-white rounded-lg overflow-hidden shadow-sm hover:shadow-md transition-shadow duration-300 flex flex-col">
      <div
        className="h-12 w-full relative overflow-hidden"
        style={{ background: gradient }}>
        <div
          className="absolute inset-0"
          style={{
            opacity: 0.3,
            backgroundImage: `url("data:image/svg+xml,%3Csvg viewBox='0 0 200 200' xmlns='http://www.w3.org/2000/svg'%3E%3Cfilter id='noiseFilter'%3E%3CfeTurbulence type='fractalNoise' baseFrequency='0.65' numOctaves='3' stitchTiles='stitch'/%3E%3C/filter%3E%3Crect width='100%' height='100%' filter='url(%23noiseFilter)'/%3E%3C/svg%3E")`,
            backgroundRepeat: "repeat",
            backgroundSize: "200px 200px",
          }}
        />
      </div>
      <div className="flex-1 flex flex-col p-5">
        <div className="flex items-center mb-4">
          {post.authorPhotoURL && (
            <img
              src={post.authorPhotoURL}
              referrerPolicy="no-referrer"
              alt="Author"
              className="w-10 h-10 rounded-full mr-4"
            />
          )}
          <span className="text-gray-800 font-semibold">{post.author}</span>
        </div>
        <p className="mb-4 text-gray-800">{post.content}</p>
        {post.audioUrl && (
          <AudioPlayer
            audioFileUrl={post.audioUrl}
            c0={randomColor1}
            c1={randomColor2}
          />
        )}
      </div>
    </li>
  );
}

export default PostCard;
