import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import SignInPage from "./SignInPage";
import HomePage from "./HomePage";
import SpotifyRoastPage from "./SpotifyRoastPage";
import PostDetailsPage from "./PostDetailsPage";
import Notifications from "./Notifications";
import { signInWithGoogle, signOut } from "./firebaseAuth";
import { getLatestPosts } from "./firebaseFirestore";
import { UserProvider } from "./UserContext";

function App() {
  const [latestPosts, setLatestPosts] = useState([]);
  const [user, setUser] = useState(() => {
    const savedUser = localStorage.getItem("user");
    return savedUser ? JSON.parse(savedUser) : null;
  });

  useEffect(() => {
    const fetchLatestPosts = async () => {
      try {
        const posts = await getLatestPosts();
        setLatestPosts(posts);
      } catch (error) {
        console.error("Error fetching latest posts:", error);
      }
    };
    fetchLatestPosts();
  }, []);

  const SignInPageWithNavigation = () => {
    const navigate = useNavigate();

    const handleSignIn = async () => {
      try {
        const userData = await signInWithGoogle();
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        navigate("/home");
      } catch (error) {
        console.error("Error during sign in:", error);
      }
    };

    const handleContinueWithUser = () => {
      navigate("/home");
    };

    return (
      <SignInPage
        onSignIn={handleSignIn}
        latestPosts={latestPosts}
        user={user}
        continueWithUser={handleContinueWithUser}
      />
    );
  };

  const HomePageWithNavigation = () => {
    const navigate = useNavigate();

    const handleSignOut = async () => {
      try {
        await signOut();
        setUser(null);
        localStorage.removeItem("user");
        navigate("/");
      } catch (error) {
        console.error("Error during sign out:", error);
      }
    };

    return <HomePage user={user} onSignOut={handleSignOut} />;
  };

  return (
    <UserProvider value={user}>
      <Router>
        <div className="flex flex-col min-h-screen">
          <div className="flex-grow">
            <Routes>
              <Route path="/" element={<SignInPageWithNavigation />} />
              <Route path="/home" element={<HomePageWithNavigation />} />
              <Route path="/roast" element={<SpotifyRoastPage />} />
              <Route path="/posts/:postId" element={<PostDetailsPage />} />
              <Route path="/notifications" element={<Notifications />} />
            </Routes>
          </div>
          <footer className="w-full  mx-auto text-center py-8 border-t border-gray-200 bg-black">
            <p className="text-white">© 2024 Hummify. All rights reserved.</p>
          </footer>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
